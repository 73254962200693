* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  background-color: whitesmoke;
  color: rgb(20, 20, 20);
  width: 100%;
  height: 100%;
  overflow: auto;
}
.main {
  display: grid;
  grid-template-rows: auto 1fr;
  max-width: 425px;
  /* min-width: max-content; */
  margin: auto;
}

/* Navbar styling */
.navbar {
  display: flex;
  gap: 3vmin;
  border-bottom: solid 1px rgb(185, 185, 185);
  padding: 0.75vmin 2vmin;
}
.navbar button {
  border: none;
  text-decoration: none;
  padding: 0;
  background-color: transparent;
  color: rgb(20, 20, 20);
  font-size: calc(1rem + 1vmin);
  cursor: pointer;
}
.navbarTitle {
  margin-left: auto;
  font-size: calc(1rem + 2vmin);
  color: rgb(20, 20, 20);
}
/* Navbar styling End*/

/* Setting Modal styling */
.settingsModalContainer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  z-index: 10;
}
.settingsModal {
  position: relative;
  background-color: whitesmoke;
  border: solid 3px rgb(185, 185, 185);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  width: 80%;
  max-width: 420px;
  margin: auto;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  max-height: 100%;
  overflow: auto;
}

.settingsModalClose {
  text-align: right;
}
.settingsModalClose button {
  text-decoration: none;
  border: none;
  background-color: transparent;
  margin: 6px 7px 0px 0px;
  font-size: calc(1rem + 2vmin);
}
.settingsModalHeader {
  display: flex;
  justify-content: center;
}
.settingsModalHeader h1 {
  width: 80%;
  text-align: center;
  font-size: calc(1rem + 2vmin);
  margin-bottom: 10px;
  border-bottom: solid 1px rgb(185, 185, 185);
}

.settingModalContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
  margin: auto;
}
.darkModeSettingsDropdownContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.darkModeSettingsDropdownContent p {
  margin-bottom: 5px;
  font-size: calc(1rem + 0.75vmin);
}
.darkModeSwitch {
  position: relative;
  width: 44px;
  height: 20px;
}
.darkModeSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.darkModeSwitch input:checked + .slider {
  background-color: #2196f3;
}
.darkModeSwitch input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}
.slider.round {
  border-radius: 30px;
}
.slider.round:before {
  border-radius: 50%;
}

.settingsModalFeedback {
  display: flex;
  justify-content: space-between;
}
.settingsModalFeedback p,
a {
  margin-bottom: 5px;
  font-size: calc(1rem + 0.75vmin);
}
/* Setting Modal styling End*/

/* Info Modal styling*/
.infoModalContainer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  z-index: 10;
}
.infoModal {
  position: relative;
  background-color: whitesmoke;
  border: solid 3px rgb(185, 185, 185);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  width: 90%;
  max-width: 420px;
  margin: auto;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  max-height: 100%;
  overflow: auto;
}

.infoModalClose {
  text-align: right;
}
.infoModalClose button {
  text-decoration: none;
  border: none;
  background-color: transparent;
  margin: 6px 7px 0px 0px;
  font-size: calc(1rem + 2vmin);
}
.headerInfoModal {
  display: flex;
  justify-content: center;
}
.headerInfoModal h1 {
  text-align: center;
  width: 80%;
  font-size: calc(1rem + 2vmin);
  margin-bottom: 10px;
  border-bottom: solid 1px rgb(185, 185, 185);
}

.bodyInfoModal {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  gap: 5px;
}
.bodyInfoModal p {
  margin-bottom: 5px;
  font-size: calc(1rem + 0.75vmin);
}

.beforeExampleBodyInfoModal {
  border-bottom: solid 1px rgb(185, 185, 185);
  padding-bottom: 5px;
}

.exampleBodyInfoModal {
  display: flex;
  gap: 2px;
}
.exampleBodyInfoModal div {
  width: calc(1rem + 3vmin);
  height: calc(1rem + 3vmin);
  border: solid 2px grey;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.exampleBodyInfoModal p {
  margin: 0;
}
.exampleBodyInfoModal .greenExampleBodyInfoModal {
  background-color: rgb(0, 167, 0);
  color: whitesmoke;
  border: none;
}
.exampleBodyInfoModal .yellowExampleBodyInfoModal {
  background-color: rgb(212, 198, 0);
  color: whitesmoke;
  border: none;
}
.exampleBodyInfoModal .greyExampleBodyInfoModal {
  background-color: rgb(80, 80, 80);
  color: whitesmoke;
  border: none;
}
.afterExampleBodyInfoModal {
  padding-top: 5px;
  border-top: solid 1px rgb(185, 185, 185);
}
/* Info Modal styling End*/

/* Share Modal styling*/
.shareModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  z-index: 10;
}
.shareModal {
  position: relative;
  background-color: whitesmoke;
  border: solid 3px rgb(185, 185, 185);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  width: 80%;
  max-width: 420px;
  margin: auto;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  max-height: 100%;
  overflow: auto;
}

.shareModalClose {
  text-align: right;
}
.shareModalClose button {
  text-decoration: none;
  border: none;
  background-color: transparent;
  margin: 6px 7px 0px 0px;
  font-size: calc(1rem + 2vmin);
}

.bodyShareModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vmin;
  width: 95%;
  margin: auto;
}

.winFalse {
  width: 100%;
}
.winFalse p {
  font-size: calc(1rem + 0.75vmin);
  margin: 5px 0;
}
.winYes {
  width: 100%;
}
.winYes p {
  font-size: calc(1rem + 0.75vmin);
  margin: 5px 0;
}
.shareWinDataContainer {
  overflow: auto;
  margin-bottom: 2vmin;
}
.shareWinData {
  display: flex;
  justify-content: center;
}
.shareWinDataContainer .shareWinData p {
  font-size: calc(1rem + 2vmin);
  margin: 0;
}
.winNo {
  width: 100%;
}
.winNo p {
  text-align: center;
  font-size: calc(1rem + 0.75vmin);
  margin: 5px 0;
}
.winNo .winNoCorrectWord {
  text-align: center;
  font-size: calc(1rem + 2vmin);
  font-weight: 900;
}

.hintUsedShareModal {
  text-align: center;
}

.bodyShareModal button {
  padding: 5px 25px;
  border: 2px solid transparent;
  color: whitesmoke;
  background-color: rgb(20, 20, 20);
  transition-property: background-color, border, color;
  transition-duration: 0.3s;
  font-size: calc(1rem + 2vmin);
  cursor: pointer;
}
.bodyShareModal button:hover {
  background-color: whitesmoke;
  color: rgb(20, 20, 20);
  border: 2px solid rgb(20, 20, 20);
}

.shareModalTimer {
  font-size: calc(1rem + 0.75vmin);
  text-align: center;
}
/* Share Modal styling End*/

/* Stats Modal styling */
.statsModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  z-index: 10;
}
.statsModal {
  position: relative;
  background-color: whitesmoke;
  border: solid 3px rgb(185, 185, 185);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  width: 80%;
  max-width: 420px;
  margin: auto;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  max-height: 100%;
  overflow: auto;
}

.statsModalClose {
  text-align: right;
}
.statsModalClose button {
  text-decoration: none;
  border: none;
  background-color: transparent;
  margin: 6px 7px 0px 0px;
  font-size: calc(1rem + 2vmin);
}
.headerStatsModal {
  display: flex;
  justify-content: center;
}
.headerStatsModal h1 {
  text-align: center;
  width: 80%;
  font-size: calc(1rem + 2vmin);
  margin-bottom: 10px;
  border-bottom: solid 1px rgb(185, 185, 185);
}

.bodyStatsModal {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  gap: 5px;
}

.mainStats {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.gamesCompletedMainStats,
.gamesWonMainStats,
.currentStreakMainStats,
.highestStreakMainStats,
.hintsUsedMainStats {
  display: flex;
  flex-direction: column;
}
.gamesCompletedMainStats p,
.gamesWonMainStats p,
.currentStreakMainStats p,
.highestStreakMainStats p,
.hintsUsedMainStats p {
  font-size: calc(0.85rem + 0.5vmin);
  text-align: center;
}
.gamesCompletedMainStats .numberStats,
.gamesWonMainStats .numberStats,
.currentStreakMainStats .numberStats,
.highestStreakMainStats .numberStats,
.hintsUsedMainStats .numberStats {
  font-size: calc(1rem + 1vmin);
}

.hintsUsedStatsModal {
  font-size: calc(0.85rem + 0.5vmin);
  text-align: center;
  margin-top: 10px;
}

.goToShareButton {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.goToShareButton button {
  width: 80%;
  padding: 5px 25px;
  border: 2px solid transparent;
  color: whitesmoke;
  background-color: rgb(20, 20, 20);
  transition-property: background-color, border, color;
  transition-duration: 0.3s;
  font-size: calc(1rem + 2vmin);
  cursor: pointer;
}
.goToShareButton button:hover {
  background-color: whitesmoke;
  color: rgb(20, 20, 20);
  border: 2px solid rgb(20, 20, 20);
}

.statsModalTimer {
  font-size: calc(1rem + 0.75vmin);
  text-align: center;
}
/* Stats Modal styling End */

.mainBody {
  width: 100%;
  height: 100%;
  overflow: auto;
}

/* Keyboard styling */
.keyboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vmin;
  /* margin-top: 2rem; */
}

.keyboardRow1,
.keyboardRow2,
.keyboardRow3 {
  display: flex;
  gap: 1vmin;
}

.keyboard button {
  padding: 0.75rem 0.25rem;
  min-width: fit-content;
  width: 29px;
  font-size: calc(0.5rem + 0.85vmin);
  font-weight: 600;
  background-color: rgb(202, 202, 202);
  color: rgb(0, 0, 0);
  /* border: solid 1px rgb(216, 0, 43); */
  border: none;
  border-radius: 10%;
}
.keyboard .keyboardButtonEnter {
  font-weight: 700;
  padding: 0.25rem 0.5rem;
}
.keyboard .keyboardButtonDelete {
  font-size: calc(0.5rem + 2vmin);
  padding: 0.25rem 0.25rem;
}

.keyboard .keyboardButtonColorCorrect {
  background-color: rgb(0, 167, 0);
  color: whitesmoke;
}
.keyboard .keyboardButtonColorAlmostCorrect {
  background-color: rgb(212, 198, 0);
  color: whitesmoke;
}
.keyboard .keyboardButtonColorWrong {
  background-color: rgb(80, 80, 80);
  color: whitesmoke;
}
/* Keyboard styling End */

/* Input answer styling */
.inputAnswerRowContainer {
  display: flex;
  justify-content: center;
}
.inputAnswer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vmin;
  margin-top: 2rem;
  width: max-content;
}

.inputAnswerRow {
  display: flex;
  gap: 1vmin;
}

.inputAnswerRow input {
  width: calc(2rem + 2vmin);
  height: calc(2rem + 2vmin);
  font-size: calc(0.5rem + 2vmin);
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  border: solid 1px grey;
  color: rgb(20, 20, 20);
  -webkit-text-fill-color: rgb(20, 20, 20);
  opacity: 1; /* required on iOS */
}
.inputAnswerRow .inputAnswerDone {
  border: solid 2px grey;
}

.inputAnswerRow .inputLetterWrong {
  background-color: rgba(80, 80, 80, 1);
  color: rgba(245, 245, 245, 1);
  -webkit-text-fill-color: rgba(245, 245, 245, 1);
  opacity: 1; /* required on iOS */
  border: none;
}
.inputAnswerRow .inputLetterCorrect {
  background-color: rgba(0, 167, 0, 1);
  color: rgba(245, 245, 245, 1);
  -webkit-text-fill-color: rgba(245, 245, 245, 1);
  opacity: 1; /* required on iOS */
  border: none;
}
.inputAnswerRow .inputLetterAlmostCorrect {
  background-color: rgba(212, 198, 0, 1);
  color: rgba(245, 245, 245, 1);
  -webkit-text-fill-color: rgba(245, 245, 245, 1);
  opacity: 1; /* required on iOS */
  border: none;
}
/* Input answer styling End*/

/* Hints container styling */
.hintsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin: auto;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.hintsButtonContainer button {
  padding: 5px 25px;
  border: 2px solid transparent;
  color: whitesmoke;
  background-color: rgb(20, 20, 20);
  transition-property: background-color, border, color;
  transition-duration: 0.3s;
  font-size: calc(0.75rem + 1vmin);
  cursor: pointer;
}
.hintsButtonContainer button:hover {
  background-color: whitesmoke;
  color: rgb(20, 20, 20);
  border: 2px solid rgb(20, 20, 20);
}
.hideHintsButtonContainer {
  display: none;
}

.hintsTextContainer p {
  font-size: calc(0.75rem + 1vmin);
}
.hideHintsTextContainer {
  display: none;
}
/* Hints container styling End */

/* Modals styling */
.modalCommence {
  position: fixed;
  margin-top: 5px;
  background-color: whitesmoke;
  border-radius: 5px;
  /* border: solid 2px white; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: rgb(20, 20, 20);
  width: fit-content;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.modalCommence p {
  text-align: center;
  font-size: calc(1rem + 0.25vmin);
  margin: 2vmin;
}

.rightWordModal {
  position: fixed;
  margin-top: 5px;
  background-color: rgb(0, 0, 0);
  border-radius: 5px;
  /* border: solid 2px white; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: whitesmoke;
  width: fit-content;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.rightWordModal p {
  text-align: center;
  font-size: calc(1rem + 0.25vmin);
  margin: 1.5vmin;
}

.shareMessageTruemodal,
.shareMessageFalsemodal {
  position: fixed;
  margin-top: 10px;
  background-color: whitesmoke;
  border-radius: 5px;
  /* border: solid 2px rgb(255, 166, 0); */
  color: rgb(20, 20, 20);
  width: fit-content;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 11;
}

.shareMessageTruemodal,
.shareMessageFalsemodal p {
  font-size: calc(1rem + 0.25vmin);
  text-align: center;
  padding: 5px;
}

.newBiblidleMessageModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
.newBiblidleMessageModal {
  position: relative;
  background-color: whitesmoke;
  width: max-content;
  max-width: 420px;
  margin: auto;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 15;
}
.newBiblidleMessageModal p {
  font-size: calc(1rem + 0.25vmin);
  text-align: center;
  padding: 5px;
  z-index: 16;
}

/* Animations */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Dark Mode Colors */

.darkMode html,
.darkMode body {
  background-color: rgb(20, 20, 20);
  color: rgb(235, 235, 235);
}

/* Navbar styling */
.darkMode .navbar {
  border-bottom: solid 1px rgb(185, 185, 185);
}

.darkMode .navbar button {
  background-color: transparent;
  color: whitesmoke;
}

.darkMode .navbarTitle {
  color: rgb(255, 255, 255);
}

/* Navbar styling End*/

/* Setting Modal styling */
.darkMode .settingsModalContainer {
  background-color: rgba(0, 0, 0, 0.1);
}

.darkMode .settingsModal {
  background-color: rgb(10, 10, 10);
  border: solid 3px rgb(185, 185, 185);
  box-shadow: rgba(255, 255, 255, 0.12) 0px 1px 3px, rgba(255, 255, 255, 0.24) 0px 1px 2px;
}

.darkMode .settingsModalHeader h1 {
  border-bottom: solid 1px rgb(185, 185, 185);
}

.darkMode .slider {
  background-color: #ccc;
}

.darkMode .slider:before {
  background-color: rgb(10, 10, 10);
}

.darkMode .darkModeSwitch input:checked + .slider {
  background-color: #2196f3;
}

.settingsModalFeedback a {
  color: rgb(0, 132, 255);
}

/* Setting Modal styling End*/

/* Info Modal styling*/
.darkMode .infoModalContainer {
  background-color: rgba(0, 0, 0, 0.1);
}

.darkMode .infoModal {
  background-color: rgb(10, 10, 10);
  border: solid 3px rgb(185, 185, 185);
  box-shadow: rgba(255, 255, 255, 0.12) 0px 1px 3px, rgba(255, 255, 255, 0.24) 0px 1px 2px;
}

.darkMode .headerInfoModal h1 {
  border-bottom: solid 1px rgb(185, 185, 185);
}

.darkMode .beforeExampleBodyInfoModal {
  border-bottom: solid 1px rgb(185, 185, 185);
}

.darkMode .exampleBodyInfoModal div {
  border: solid 2px grey;
}

.darkMode .exampleBodyInfoModal .greenExampleBodyInfoModal {
  background-color: rgb(0, 150, 0);
  color: whitesmoke;
  border: none;
}

.darkMode .exampleBodyInfoModal .yellowExampleBodyInfoModal {
  background-color: rgb(180, 168, 0);
  color: whitesmoke;
  border: none;
}

.darkMode .exampleBodyInfoModal .greyExampleBodyInfoModal {
  background-color: rgb(80, 80, 80);
  color: whitesmoke;
  border: none;
}

.darkMode .afterExampleBodyInfoModal {
  border-top: solid 1px rgb(185, 185, 185);
}

/* Info Modal styling End*/

/* Share Modal styling*/
.darkMode .shareModalContainer {
  background-color: rgba(0, 0, 0, 0.1);
}

.darkMode .shareModal {
  background-color: rgb(10, 10, 10);
  border: solid 3px rgb(185, 185, 185);
  box-shadow: rgba(255, 255, 255, 0.12) 0px 1px 3px, rgba(255, 255, 255, 0.24) 0px 1px 2px;
}

.darkMode .bodyShareModal button {
  border: 2px solid transparent;
  color: rgb(20, 20, 20);
  background-color: rgb(235, 235, 235);
}

.darkMode .bodyShareModal button:hover {
  background-color: rgb(25, 25, 25);
  color: whitesmoke;
  border: 2px solid whitesmoke;
}
/* Share Modal styling End*/

/* Stats Modal styling */
.darkMode .statsModalContainer {
  background-color: rgba(0, 0, 0, 0.1);
}
.darkMode .statsModal {
  background-color: rgb(10, 10, 10);
  border: solid 3px rgb(185, 185, 185);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.darkMode .headerStatsModal h1 {
  border-bottom: solid 1px rgb(185, 185, 185);
}

.darkMode .goToShareButton button {
  border: 2px solid transparent;
  color: rgb(20, 20, 20);
  background-color: rgb(235, 235, 235);
}
.darkMode .goToShareButton button:hover {
  background-color: rgb(25, 25, 25);
  color: whitesmoke;
  border: 2px solid whitesmoke;
}
/* Stats Modal styling End */

/* Keyboard styling */

.darkMode .keyboard button {
  background-color: rgb(115, 115, 115);
  color: rgb(255, 255, 255);
}

.darkMode .keyboard .keyboardButtonColorCorrect {
  background-color: rgb(0, 150, 0);
  color: whitesmoke;
}

.darkMode .keyboard .keyboardButtonColorAlmostCorrect {
  background-color: rgb(180, 168, 0);
  color: whitesmoke;
}

.darkMode .keyboard .keyboardButtonColorWrong {
  background-color: rgb(45, 45, 45);
  color: whitesmoke;
}

/* Keyboard styling End */

/* Input answer styling */

.darkMode .inputAnswerRow input {
  border: solid 1px grey;
  color: whitesmoke;
  -webkit-text-fill-color: whitesmoke;
  opacity: 1; /* required on iOS */
  background-color: rgb(25, 25, 25);
}

.darkMode .inputAnswerRow .inputAnswerDone {
  border: solid 2px grey;
}

.darkMode .inputAnswerRow .inputLetterWrong {
  background-color: rgba(80, 80, 80, 1);
  color: rgba(245, 245, 245, 1);
  -webkit-text-fill-color: rgba(245, 245, 245, 1);
  opacity: 1; /* required on iOS */
  border: none;
}

.darkMode .inputAnswerRow .inputLetterCorrect {
  background-color: rgba(0, 150, 0, 1);
  color: rgba(245, 245, 245, 1);
  -webkit-text-fill-color: rgba(245, 245, 245, 1);
  opacity: 1; /* required on iOS */
  border: none;
}

.darkMode .inputAnswerRow .inputLetterAlmostCorrect {
  background-color: rgba(180, 168, 0, 1);
  color: rgba(245, 245, 245, 1);
  -webkit-text-fill-color: rgba(245, 245, 245, 1);
  opacity: 1; /* required on iOS */
  border: none;
}

/* Input answer styling End*/

/* Hints container styling */
.darkMode .hintsButtonContainer button {
  border: 2px solid transparent;
  color: rgb(20, 20, 20);
  background-color: rgb(235, 235, 235);
}
.darkMode .hintsButtonContainer button:hover {
  background-color: rgb(25, 25, 25);
  color: whitesmoke;
  border: 2px solid whitesmoke;
}
/* Hints container styling End */

/* Modals styling */
.darkMode .modalCommence {
  background-color: whitesmoke;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: rgb(20, 20, 20);
}

.darkMode .rightWordModal {
  background-color: whitesmoke;
  /* border: solid 2px white; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: rgb(20, 20, 20);
}

.darkMode .shareMessageTruemodal,
.darkMode .shareMessageFalsemodal {
  background-color: whitesmoke;
  color: rgb(20, 20, 20);
}

.darkMode .newBiblidleMessageModalContainer {
  background-color: rgba(0, 0, 0, 0.1);
}

.darkMode .newBiblidleMessageModal {
  background-color: whitesmoke;
  color: rgb(20, 20, 20);
}
